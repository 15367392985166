import React from 'react';
import {
  Box
} from 'theme-ui';
import gradientBar from '../images/gradient_bar.jpg';

function GradientBar() {
  return (
    <Box
      sx={{
        background: `url(${gradientBar})`,
        backgroundSize: ['contain', null, 'cover'],
        width: '100%',
        height: 20,
        zIndex: 9
      }}>
    </Box>
  )
}

export default GradientBar;
