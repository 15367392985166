const theme = {
  fonts: {
    body: '"Benton Sans", sans-serif',
    heading: '"Benton Sans", sans-serif'
  },
  colors: {
    text: '#000',
    primary: '#585BA8',
    headingAlt: '#5959AD',
    vizioOrange: '#E79A49',
    background: '#000',
    vizioRed: '#D71E84'
  },
  lineHeights: {
    heading: '1.1',
    body: '1.5'
  },
  buttons: {
    primary: {
      padding: '1rem 2.5rem',
      color: 'white',
      bg: '#D71E84',
    },
    secondary: {
      padding: '1rem 2.5rem',
      fontSize: 3,
      color: 'primary',
      bg: 'white',
    },
  },
  sizes: {
    container: 1280,
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body'
    },
  },
}

export default theme
